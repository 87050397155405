<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        class="col-12"
        label="ข้อความ"
        v-model="formData.message"
        :validations="[
          {text: 'required!', value: $v.formData.message.$dirty && !$v.formData.message.required}
        ]">
      </sgv-input-text>
    </div>

    <button
      type="button"
      class="btn btn-warning"
      @click="sendMessage">
      Send Message
    </button>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { SEND_DEVICE_MESSAGE } from './graph'

export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        message: ''
      }
    }
  },
  validations: {
    formData: {
      message: { required },
    }
  },
  methods: {
    sendMessage () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: SEND_DEVICE_MESSAGE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          message: this.formData.message
        }
      }).then(() => {
        this.$v.formData.$reset()
        this.$toasted.global.success('success')
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
